import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Error404View from '../views/Error404View.vue';
import LibraryView from '../views/LibraryView.vue';
import ProjectView from '../views/ProjectView.vue';
import QuizView from '../components/tutorial/QuizView.vue';
import TutorialView from '../components/tutorial/TutorialView.vue';
import PostView from '../views/PostView.vue';

import store from '../store/index';

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/404', name: '404', component: Error404View },
  { path: '/library', name: 'library', component: LibraryView },
  { path: '/projects/:slug', name: 'project', component: ProjectView },
  { path: '/projects/:slug/:part(.*-quiz)', name: 'project-tutorial-quiz', component: QuizView },
  { path: '/projects/:slug/:part', name: 'project-tutorial-part', component: TutorialView },
  {
    path: '/blog',
    name: 'blog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/BlogView.vue')
  },
  { path: '/blog/:ref(.*)*', name: 'blog-post', component: PostView },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  { // catch-all non-matching routes as 404
    path: '/(.*)*', component: Error404View,
  },
  {
    path: '/admin/post-creator',
    name: 'admin-post-creator',
    component: () => import('../views/admin/PostCreatorView.vue')
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('../views/admin/DashboardView.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    let top;
    if (to.name === from.name) {
      top = savedPosition; // (stay at same scroll)
    } else {
      top = 0; // (scroll to top)
    }
    // scroll in page
    return { top };
  },
});

export const routerData = {};

router.beforeEach(async (to, from) => {
  store.commit('global/setPageScroll', { scrollable: true });

  if (from.name === 'library' || from.name === 'blog')
    routerData.lastListPath = from.fullPath;
  if (to.name === 'home')
    routerData.lastListPath = null;

  return true;
});

export default router;
