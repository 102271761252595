<template>
  <div
    class="overlay-panel"
    :class="['overlay-panel', hovered ? 'hovered' : '', fullPage ? 'full-page' : '']"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <div class="overlay-lock">
      <q-icon name="lock" size="6rem"></q-icon>
    </div>
    <div v-if="hovered" class="overlay-content text-white text-center">
      <div :class="[classLabel, 'text-uppercase', 'non-selectable']">
        <div class="text-uppercase text-weight-medium non-selectable q-mb-md" style="font-size: 1.3rem;">
          locked for now...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayLockPanel',
  props: {
    locked: { type: Boolean },
    classLabel: { type: String, required: true },
    classTitle: { type: String, required: true },
    fullPage: { type: Boolean, default: true },
  },
  data() {
    return {
      hovered: false,
    };
  },
}
</script>

<style lang="sass">
.overlay-panel
  z-index: 100
  background-color: rgba(60, 60, 60, 0.55)
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  display: grid
  place-items: center
  transition: background-color 0.2s linear
  max-height: 100vh
.overlay-panel.hovered
  background-color: rgba(10, 10, 10, 0.75)

.overlay-lock
  color: $grey-2
  border: 8px solid $grey-2
  border-radius: 50%
  opacity: 0.85
  padding: 0.5rem
  transform: none
  transition: transform 0.35s ease
.overlay-panel.hovered .overlay-lock
  transform: translateY(-50%)

.overlay-content
  position: absolute
  width: 100%
  top: 60%
  left: 50%
  transform: translateX(-50%)
  opacity: 0
  transition: opacity 0.35s ease
.overlay-panel.full-page .overlay-content
  top: 50%
.overlay-panel.hovered .overlay-content
  opacity: 1
</style>
