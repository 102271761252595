<template>
  <div id="post" style="{ overflow-y: isAccessible ? 'initial' : 'hidden'; max-height: 100vh; }">
    <template v-if="!loading">
      <q-page-container>
        <q-page>
          <!-- medium/large screens -->
          <SideFloatingPanel id="side-panel" v-if="$q.screen.gt.sm" style="bottom: 24px; min-width: 160px;">
            <q-btn flat icon="chevron_left" label="back to list" color="grey-9"
              @click="backToList()" class="q-pl-none"></q-btn>
          </SideFloatingPanel>
          <!-- small screens -->
          <div v-else ref="bottom-bar" class="bottom-bar">
            <q-btn flat icon="chevron_left" label="back" color="grey-9"
              @click="backToList" class="back-button q-pl-none" size="16px"></q-btn>
          </div>

          <div class="read-bar-wrapper">
            <div class="read-bar" :style="{ width: `${readRatio}%` }"></div>
          </div>

          <q-parallax
            :src="$options.getRelativeImg(data.banner || '/trianglify-blog.png')"
            class="parallax-cover"
            :height="300"
            :speed="0.8"
          ></q-parallax>
    
          <div class="main-container inner-container relative">
            <div :class="['column q-mb-md items-center', $q.screen.gt.md ? 'q-px-md' : '']">
              <div
                :class="[$q.screen.gt.md ? 'text-h2' : 'text-h3', 'q-mt-xl q-mb-sm text-center']"
                style="max-width: 90vw;"
              >{{ data.title }}</div>
              <h5 class="q-mt-md q-mb-sm text-center text-grey-9">{{ date }}</h5>
              <div class="q-mb-md text-center text-grey-9 text-body1">{{ readTime }}</div>

              <div :class="[$q.screen.gt.md ? 'q-px-xl' : '', 'q-pt-xl text-body1']" v-html="data.content"></div>
            </div>

            <q-scroll-observer @scroll="onScroll" />
          </div>

          <q-btn
            v-if="previousPost"
            color="primary"
            icon="chevron_left"
            :to="`/blog/${previousPost}`"
            class="navbutton previous"
            round
            size="16px"
          ></q-btn>
          <q-btn
            v-if="nextPost"
            color="primary"
            icon="chevron_right"
            :to="`/blog/${nextPost}`"
            class="navbutton next"
            round
            size="16px"
          ></q-btn>

          <Footer></Footer>
        </q-page>
      </q-page-container>
    </template>
    <template v-else>
      <q-inner-loading :showing="loading">
        <q-spinner-hourglass
          color="primary"
          size="4em"
          class=""
        />
      </q-inner-loading>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { routerData } from '@/router/index.js';
import { date as dateFormatter } from '@/helpers/formatters.js';
import { getRelativeImg, highlightCode, fitContainerWithSidePanel } from '@/helpers/html.js';

import Footer from '@/components/layout/Footer.vue';
import SideFloatingPanel from '@/components/layout/SideFloatingPanel.vue';

export default {
  getRelativeImg,
  name: 'Post',
  components: {
    Footer,
    SideFloatingPanel,
  },
  data() {
    return {
      previousPost: null,
      nextPost: null,

      loading: true,
      data: null,
      showShopDialog: false,
      slide: 0,
      tab: null,

      readRatio: 0,
    };
  },
  computed: {
    ...mapState({
      posts: (state) => state.posts.posts,
    }),
    date() {
      return dateFormatter(this.data.date);
    },
    readTime() {
      if (!this.data.content) return '';
      const text = this.data.content;
      const nWords = text.trim().split(/\s+/).length;
      return `Reading time: ${Math.ceil(nWords / 265)} min`; // 265 words per minute
    },
  },
  async created() {
    if (this.posts.length === 0)
      await this.$store.dispatch('posts/getPosts');

    this.getPostData();
  },
  updated() {
    highlightCode();
    fitContainerWithSidePanel();
  },
  methods: {
    backToList() {
      const { lastListPath } = routerData;
      if (lastListPath) this.$router.push(lastListPath);
      else this.$router.push({ name: 'blog' });
    },
    async getPostData() {
      try {
        let { ref } = this.$route.params;
        ref = ref.join('/');
        const postData = this.$store.getters['posts/getPostByRef'](ref);
        if (!postData) {
          this.$router.push({ name: '404' });
        } else {
          this.previousPost = postData.previous;
          this.nextPost = postData.next;
          this.data = postData.post;
          if (!('content' in this.data)) {
            this.data = {
              ...this.data,
              ...(await this.$store.dispatch('posts/getPostData', { ref }))
            };
          }
        }
  
        this.loading = false;
      } catch {
        this.$router.push({ name: '404' });
      }
    },
    onScroll(scrollInfo) {
      const elem = document.documentElement, body = document.body;
      const scrollTop = elem.scrollTop  || body.scrollTop;
      const scrollBottom = (elem.scrollHeight  || body.scrollHeight) - window.innerHeight;
      this.readRatio = Math.min(100.0 * scrollTop / scrollBottom, 100.0);

      // on mobiles
      const bottomBar = this.$refs['bottom-bar'];
      if (bottomBar) {
        if (scrollInfo.position.top < 10) bottomBar.classList.remove('visible');
        else bottomBar.classList.add('visible');
      }
    },
  },
  watch: {
    '$route.path': {
      handler: async function(newPath, oldPath) {
        if (oldPath && newPath !== oldPath && newPath.startsWith('/blog/')) {
          this.loading = true;
          this.readRatio = 0;
          await this.getPostData();
        }
      },
      deep: true,
      immediate: true
    }
  },
}
</script>

<style lang="sass" scoped>
.parallax-cover img
  background-attachment: fixed
  background-position: center
  background-repeat: no-repeat
  background-size: cover

.custom-caption
  text-align: center
  padding: 12px
  color: white
  background-color: rgba(0, 0, 0, .3)

.main-container:deep(.q-carousel__navigation--bottom)
  bottom: 72px

.main-container:deep(.q-carousel__navigation .q-btn .q-icon)
  border: 1px solid $grey-5
  border-radius: 50%

.main-container:deep(.q-tab__label)
  font-size: 1rem


.tabs-sticky
  position: sticky
  top: 48px
  z-index: 99
  background-color: white
  padding-top: 12px

.q-tab-panel:deep(h6)
  margin-top: 28px
  margin-bottom: 14px

.q-tab-panel:deep(table)
  border-collapse: collapse
  margin: 1rem auto

.q-tab-panel:deep(table td),
.q-tab-panel:deep(table th)
  border: 1px solid currentColor
  padding: 0.35rem 0.65rem

.navbutton
  position: fixed
  transform: translateY(-50%)
  text-align: center
  top: 40%
  body.screen--xs &,
  body.screen--sm &
    top: 64px
    transform: none
.navbutton.previous
  left: 16px
.navbutton.next
  right: 16px

.read-bar-wrapper
  position: fixed
  top: 50px
  left: 0
  right: 0
  height: 7px
  background-color: hsla(32, 100%, 50%, 0.5)
  z-index: 9
.read-bar
  height: 100%
  background-color: $primary
</style>
