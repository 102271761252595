<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :width="size" :height="size" :fill="color" stroke=none>
    <path d="m 111.8,62.2 c 56.54456,44.80903 100.65995,103.73005 136.71826,165.77171 4.84824,11.29683 8.8427,18.81324 13.58005,2.55875 35.57849,-62.14871 79.593,-120.81197 135.36114,-166.244705 25.21753,-18.128239 56.88143,-38.643683 89.24153,-29.704896 25.75606,11.074483 24.88996,43.795612 22.9434,67.341361 -3.72034,44.81408 -5.3546,90.12638 -14.13245,134.28006 -11.68478,42.71691 -55.14601,68.67588 -97.73224,70.53053 -11.01772,2.26948 -44.74634,-2.01239 -39.91245,-1.38299 35.35602,7.16644 77.11278,19.62874 93.37471,55.25905 13.16308,34.79221 -13.19571,67.68691 -37.95247,89.19083 -23.37167,21.86272 -59.27732,40.64911 -90.34505,23.2175 C 285.04276,450.71451 270.7737,405.94555 256,367.3 241.22157,405.96057 226.91859,450.71303 189.0126,473.0172 156.77001,490.99346 120.02774,470.452 96.283487,447.61538 72.483003,426.46566 47.979655,394.12275 60.797736,360.60887 77.936868,323.47932 122.02628,311.42291 158.9,304.5 111.04116,313.24582 52.512699,302.77258 26.035464,257.6743 8.0783958,226.41486 11.111309,188.83134 6.3437855,154.20583 5.0460038,122.69006 -2.4248279,90.55113 4.5126953,59.330078 10.886201,28.17868 49.098224,27.118551 72.182394,39.259589 86.308782,45.094003 99.726375,52.948682 111.8,62.2 Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconBluesky',
  props: {
    color: { type: String, default: '#ffffff' },
    size: { type: Number, default: 24 },
  }
}
</script>
