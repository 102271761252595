<template>
  <q-card style="position: relative; display: grid; grid-template-rows: auto 1fr auto;">
    <template v-if="data">
      <q-responsive :ratio="1.6">
        <router-link :to="`/projects/${data.slug}`">
          <div class="banner-wrapper" style="width: 100%">
            <q-img spinner-color="white" :src="$options.getRelativeImg(banner)" style="width: 100%;"></q-img>
          </div>
        </router-link>
      </q-responsive>

      <ProjectTagType
        :type="data.type"
        elem-class="absolute"
        style="top: 8px; right: 8px;"
      ></ProjectTagType>
      <ProjectTagState
        v-if="showState"
        :state="data.state"
        elem-class="absolute"
        style="top: 42px; right: 8px;"
      ></ProjectTagState>

      <q-card-section>
        <div class="text-h6">{{ data.name }}</div>
        <div class="text-body1">{{ data.desc }}</div>
      </q-card-section>

      <q-card-actions class="row no-wrap items-center q-px-md">
        <q-btn
          :to="`/projects/${data.slug}`"
          push
          color="primary"
          label="Learn more"
        ></q-btn>
      </q-card-actions>
    </template>

    <template v-else>
      <q-card-section>
        <div class="column items-center">
          <q-icon name="warning" color="negative" size="4rem" />
          <div class="text-body1">Cannot load project:</div>
          <div class="text-h6">{{ slug }}</div>
        </div>
      </q-card-section>
    </template>

    <OverlayLockPanel
      v-if="!isAccessible"
      class-label="text-h6"
      class-title="text-h4"
      :full-page="false"
    ></OverlayLockPanel>
  </q-card>
</template>

<script>
import { mapState } from 'vuex';
import { getRelativeImg } from '@/helpers/html.js';

import OverlayLockPanel from '@/components/layout/OverlayLockPanel.vue';
import ProjectTagState from '@/components/project/ProjectTagState.vue';
import ProjectTagType from '@/components/project/ProjectTagType.vue';

export default {
  getRelativeImg,
  name: 'ProjectCard',
  components: {
    OverlayLockPanel,
    
    ProjectTagState,
    ProjectTagType
  },
  props: {
    slug: { type: String, required: true },
    showState: { type: Boolean, default: true },
  },
  data() {
    return {
      data: null,
      overlayHovered: false,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
    }),
    isAccessible() {
      return this.$store.getters['projects/isProjectAccessible'](this.slug);
    },
    banner() {
      if (!this.data) return null;
      if ('banner' in this.data) return this.data.banner;
      return `/trianglify-${this.data.type}.png`;
    },
  },
  created() {
    this.data = this.$store.getters['projects/getProjectBySlug'](this.slug);
  },
}
</script>

<style lang="sass" scoped>
.banner-wrapper
  overflow: hidden

.banner-wrapper .q-img
  transform: none
  transition: transform 0.25s ease
.banner-wrapper:hover .q-img
  transform: scale(1.05)
</style>
