<template>
  <q-page-container>
    <q-page id="home">
      <template v-if="!loading">
        <q-parallax :height="700">
          <template v-slot:media>
            <img :src="$options.getRelativeImg('/banner.png')">
          </template>

          <template v-slot:content="scope">
            <div
              class="absolute column items-center"
              :style="{
                opacity: 0.65 + (1 - scope.percentScrolled) * 0.75,
                top: (scope.percentScrolled * 45) + '%',
                left: 0,
                right: 0
              }"
            >
              <img :src="$options.getRelativeImg('/mp-logo_simple.png')" style="width: 150px; height: 150px">
              <div class="text-h3 text-white text-center">Mina Pêcheux</div>
              <div class="text-h6 text-grey-3 text-center">
                <div>
                  Freelance Game dev & Content Creator
                </div>
                <div class="justify-center" style="display: flex; column-gap: 1rem;">
                  <q-btn push size="lg" class="q-mt-lg" color="primary" :to="{ name: 'library' }">
                    Discover
                  </q-btn>
                  <q-btn
                    push size="lg" class="q-mt-lg"
                    href="https://www.patreon.com/minapecheux"
                    target="_blank"
                    style="background-color: white; color: rgb(236, 49, 24);"
                  >
                    <img :src="$options.getRelativeImg('/patreon.png')" style="width: auto; height: 32px;" class="q-mr-sm">
                    Support me
                  </q-btn>
                </div>
              </div>
            </div>
          </template>
        </q-parallax>
    
        <div class="main-container">
  
          <div class="inner-container q-my-xl">
            <h4 class="text-center q-mb-sm q-pt-md">This week's highlights</h4>
            <div class="row justify-center items-center">
              <div style="font-size: 1.5rem;">among</div>
              <q-btn round color="primary" class="q-mx-md" text-color="white" style="font-size: 20px;" :to="{ name: 'library' }">{{ projects.length }}</q-btn>
              <div style="font-size: 1.5rem;"> projects</div>
            </div>
            <div :class="[$q.screen.lt.md ? '' : 'row', 'q-pa-xl q-gutter-lg items-stretch']">
              <div class="col" v-for="(highlight, idx) in highlights" :key="idx">
                <ProjectCard style="height: 100%" :slug="highlight"></ProjectCard>
              </div>
            </div>
            <router-link class="text-h6 justify-center items-center" style="display: flex; text-decoration: none;" :to="{ name: 'library' }">
              Discover more
              <q-icon name="arrow_forward" class="q-ml-sm"></q-icon>
            </router-link>
            
            <h4 class="text-center q-mb-sm q-pt-md">Latest articles</h4>
            <div class="row justify-center items-center">
              <div style="font-size: 1.5rem;">among</div>
              <q-btn round color="primary" class="q-mx-md" text-color="white" style="font-size: 20px;" :to="{ name: 'blog' }">{{ posts.length }}</q-btn>
              <div style="font-size: 1.5rem;"> posts</div>
            </div>
            <div class="q-pa-xl q-col-gutter-lg">
              <div v-for="i in 3" :key="i">
                <PostCard :slug="posts[i - 1].slug"></PostCard>
              </div>
            </div>
            <router-link class="text-h6 justify-center items-center" style="display: flex; text-decoration: none;" :to="{ name: 'blog' }">
              Read more
              <q-icon name="arrow_forward" class="q-ml-sm"></q-icon>
            </router-link>
          </div>
  
        </div>
      </template>
      <template v-else>
        <q-inner-loading :showing="loading">
          <q-spinner-hourglass
            color="primary"
            size="4em"
            class=""
          />
        </q-inner-loading>
      </template>
    </q-page>
  </q-page-container>
</template>

<script>
import { mapState } from 'vuex';
import { getRelativeImg } from '@/helpers/html.js';

import ProjectCard from '@/components/project/ProjectCard.vue';
import PostCard from '@/components/post/PostCard.vue';

export default {
  getRelativeImg,
  name: 'Home',
  components: { ProjectCard, PostCard },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
      posts: (state) => state.posts.posts,
      highlights: (state) => state.projects.highlights,
    }),
  },
  async created() {
    if (this.projects.length === 0)
      await this.$store.dispatch('projects/getProjects');
    if (this.posts.length === 0)
      await this.$store.dispatch('posts/getPosts');
    await this.$store.dispatch('projects/getHighlights');
    this.loading = false;
  },
}
</script>
