<template>
  <q-card v-if="data" style="position: relative; display: grid; grid-template-rows: auto 1fr auto;">
    <q-card-section v-if="$q.screen.gt.sm" horizontal> <!-- medium/large screens -->
      <router-link :to="`/blog/${data.url}`">
        <q-responsive :ratio="1.6" style="width: 250px; height: 100%;">
          <q-img
            class="col-5"
            :src="$options.getRelativeImg(banner)"
          />
        </q-responsive>
      </router-link>

      <q-card-section style="width: 100%;">
        <router-link :to="`/blog/${data.url}`" style="text-decoration: none;">
          <div class="text-h6 text-grey-9">{{ data.title }}</div>
        </router-link>
        <div class="text-grey-8">{{ date }}</div>
        <div class="text-body1">{{ data.abstract }}</div>
        <div class="row justify-end items-center q-mt-lg">
          <q-btn
            push
            color="primary"
            label="Read more"
            :to="`/blog/${data.url}`"
            class="q-ml-lg"
          ></q-btn>
        </div>
      </q-card-section>
    </q-card-section>
    <q-card-section v-else>
      <router-link :to="`/blog/${data.url}`">
        <q-responsive :ratio="4" style="width: 100%;" class="q-mb-md">
          <q-img :src="$options.getRelativeImg(banner)" class="rounded-borders" />
        </q-responsive>
      </router-link>
      <router-link :to="`/blog/${data.url}`" style="text-decoration: none;">
        <div class="text-body1 text-weight-medium text-grey-9" style="font-size: 1.1rem;">
          {{ data.title }}
        </div>
      </router-link>
      <div class="text-grey-8">{{ date }}</div>
      <div style="font-size: 0.9rem;">{{ data.abstract }}</div>
      <div class="row justify-end items-center q-mt-lg">
        <q-btn
          push
          color="primary"
          label="Read more"
          :to="`/blog/${data.url}`"
          class="q-ml-lg"
        ></q-btn>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { date as dateFormatter } from '@/helpers/formatters.js';
import { getRelativeImg } from '@/helpers/html.js';

export default {
  getRelativeImg,
  name: 'PostCard',
  props: {
    slug: { type: String, required: true },
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    date() {
      return dateFormatter(this.data.date);
    },
    banner() {
      if (!this.data) return null;
      if ('banner' in this.data) return this.data.banner;
      return '/trianglify-blog.png';
    },
  },
  created() {
    this.data = this.$store.getters['posts/getPostBySlug'](this.slug);
  },
}
</script>

<style lang="sass" scoped>
.card-row
  display: grid
  grid-template-columns: auto 1fr
  column-gap: 1rem
  margin-bottom: 1rem
</style>
