<template>
  <div id="tutorial" style="{ overflow-y: 'hidden', max-height: 100vh }">
    <template v-if="!loading">
      <q-page-container style="{ height: calc(100vh - 50px) }">
        <q-page id="page" class="relative" :style="pageStyle">
          <template v-if="data">
            <!-- medium/large screens -->
            <SideFloatingPanel id="side-panel" v-if="$q.screen.gt.sm" style="top: 150px; width: 350px; max-height: calc(100vh - 200px); overflow: hidden;">
              <TutorialTOC
                :data="project.meta.sections"
                :project-slug="project.slug"
                mode="sidebar"
              ></TutorialTOC>
            </SideFloatingPanel>
            <!-- small screens -->
            <template v-else>
              <div ref="bottom-bar" :class="['bottom-bar extendable', showTOC ? 'extended' : 'visible']">
                <!-- default visual (condensed at bottom) -->
                <template v-if="!showTOC">
                  <q-space></q-space>
                  <q-btn flat round icon="list" color="grey-9" @click="toggleBottomBar(true)"></q-btn>
                  <q-space></q-space>
                </template>
                <!-- extended visual (= full-height TOC) -->
                <div v-else>
                  <div class="row">
                    <q-space></q-space>
                    <q-btn flat round icon="expand_more" color="grey-9" @click="toggleBottomBar(false)"></q-btn>
                    <q-space></q-space>
                  </div>
                  <TutorialTOC
                    :data="project.meta.sections"
                    :project-slug="project.slug"
                    mode="sidebar"
                    :max-height="$q.screen.height - 152"
                  ></TutorialTOC>
                </div>
              </div>
            </template>

            <div class="main-container inner-container" :style="{ 'padding-left': $q.screen.gt.sm ? '200px' : '0' }">
              <h4 class="q-mb-md">Section {{ section.index + 1 }}: {{ section.name }}</h4>
              <h5 class="q-mt-none items-center" style="display: flex;">
                <q-icon class="q-mr-sm" name="star"></q-icon> Self-evaluation quiz
              </h5>

              <q-card class="q-mb-xl">
                <q-card-section class="text-body1 text-weight-medium">
                  Wanna brush up your skills and check you've understood this section? Do this quick quiz to self-evaluate!
                </q-card-section>
              </q-card>

              <div v-if="score !== -1" class="q-my-xl">
                <q-linear-progress :value="score" size="xl" rounded :color="scoreInfo.color" />
                <div :class="`text-h5 text-${scoreInfo.color}-9 text-center q-my-md`">
                  {{ finishedItems.length }}/{{ data.questions.length }} - {{ scoreInfo.comment }}
                </div>
              </div>

              <QuizQuestion
                ref="questions"
                v-for="(question, index) in data.questions"
                :key="index"
                :data="question"
                :index="index + 1"
                class="q-mb-md"
                :is-valid="finishedItems.includes(`q${index}`) ? true : null"
                :pre-answer="finishedItems.includes(`q${index}`) ? data.questions[index].answer : null"
              ></QuizQuestion>

              <div class="row justify-end">
                <q-btn v-if="!isFinished" push color="primary" class="q-mt-lg" @click="submitAnswers" size="lg">submit</q-btn>
              </div>

              <div v-if="$q.screen.lt.md" class="q-mt-lg justify-between" style="display: flex;">
                <q-chip
                    v-if="prevChapter"
                    :clickable="prevChapter.accessible"
                    @click="() => { if (prevChapter.accessible) $router.push(prevChapter.route); }"
                    :color="prevChapter.accessible ? 'primary' : 'grey-4'"
                    :text-color="prevChapter.accessible ? 'white' : 'grey-9'"
                    icon="chevron_left"
                  >
                    Previous chapter
                  </q-chip>
                <div v-else></div>
                <q-chip
                    v-if="nextChapter"
                    :clickable="nextChapter.accessible"
                    @click="() => { if (nextChapter.accessible) $router.push(nextChapter.route); }"
                    :color="nextChapter.accessible ? 'primary' : 'grey-4'"
                    :text-color="nextChapter.accessible ? 'white' : 'grey-9'"
                    icon-right="chevron_right"
                  >
                    Next chapter
                  </q-chip>
                <div v-else></div>
              </div>
            </div>
          </template>

          <Footer></Footer>
        </q-page>
      </q-page-container>

      <OverlayLockPanel
        :locked="true"
        class-label="text-h4 q-my-md"
        class-title="text-h2"
      ></OverlayLockPanel>
    </template>
    <template v-else>
      <q-inner-loading :showing="loading">
        <q-spinner-hourglass
          color="primary"
          size="4em"
          class=""
        />
      </q-inner-loading>
    </template>
  </div>
</template>

<script>
import slugify from 'slugify';
import { mapGetters, mapState } from 'vuex';
import { delay } from '@/helpers/misc.js';
import { blockPageScroll, fitContainerWithSidePanel } from '@/helpers/html.js';

import Footer from '@/components/layout/Footer.vue';
import SideFloatingPanel from '@/components/layout/SideFloatingPanel.vue';
import OverlayLockPanel from '@/components/layout/OverlayLockPanel.vue';
import TutorialTOC from '@/components/tutorial/TutorialTOC.vue';
import QuizQuestion from '@/components/tutorial/QuizQuestion.vue';
import cloneDeep from 'lodash.clonedeep';

const toSlug = (name) => {
  return slugify(name).replaceAll('"', '');
};

export default {
  toSlug,
  name: 'Tutorial',
  components: {
    Footer,
    SideFloatingPanel,
    OverlayLockPanel,

    TutorialTOC,
    QuizQuestion,
  },
  data() {
    return {
      loading: true,
      project: null,
      section: null,
      data: null,
      submitted: false,
      showTOC: false,

      prevChapter: null,
      nextChapter: null,

      fitContainer: false,
    };
  },
  computed: {
    ...mapGetters('projects', [
      'isTutorialAccessible',
    ]),
    ...mapState({
      projects: (state) => state.projects.projects,
    }),
    finishedItems() {
      // let finished = ['q0', 'q1'...]
      return [];
    },
    isFinished() {
      return this.finishedItems.length === this.data.questions.length;
    },
    score() {
      const nFinishedItems = this.finishedItems.length;
      if (!this.submitted && nFinishedItems === 0) return -1;
      if ('quiz' in this.finishedItems) return 1;
      return nFinishedItems * 1.0 / this.data.questions.length;
    },
    pageStyle() {
      return {
        'max-height': 'calc(100vh - 50px)',
        'overflow-y': 'hidden',
      };
    },
    scoreInfo() {
      let color = 'green', comment = 'Amazing!';
      if (this.score < 0.25) {
        color = 'red'; comment = 'Might need another shot...';
      } else if (this.score < 0.5) {
        color = 'amber'; comment = 'Almost there!';
      } else if (this.score < 0.75) {
        color = 'light-green'; comment = 'Nice!';
      }

      return { color, comment };
    },
  },
  async created() {
    if (this.projects.length === 0)
      await this.$store.dispatch('projects/getProjects');
      
    if (!this.data) {
      await delay(200);
      this.getQuizData();
    }
  },
  updated() {
    if (!this.$refs.questions) return;
    for (const [index, ref] of Object.entries(this.$refs.questions)) {
      const finished = this.finishedItems.includes(`q${index}`);
      ref.reset(finished, this.data.questions[index].answer);
    }
    if (!this.fitContainer) {
      fitContainerWithSidePanel(32);
      let i = 0;
      for (const question of this.data.questions) {
        if (question.type === 'match') {
          this.$refs.questions[i].setSvgOffset();
        }
        i++;
      }
      this.fitContainer = true;
    }
  },
  beforeUnmount() {
    blockPageScroll(false);
  },
  watch: {
    '$route.name': {
      handler: async function(newName, oldName) {
        blockPageScroll(false);

        if (newName === oldName && oldName === 'project-tutorial-quiz') {
          this.data.questions = []; // force DOM emptying before refresh
                                    // to avoid confusion in data
          this.$nextTick(async () => {
            this.showTOC = false;
            this.loading = true;
  
            this.prevChapter = null;
            this.nextChapter = null;

            await this.getQuizData();
          });
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    async getQuizData() {
      try {
        const { slug, part } = this.$route.params;
        this.project = this.$store.getters['projects/getProjectBySlug'](slug);
        if (!this.project || this.project.type !== 'tutorial') {
          this.$router.push({ name: '404' });
        } else if (!('content' in this.project)) {
          this.project = {
            ...this.project,
            ...(await this.$store.dispatch('projects/getProjectData', { slug }))
          };
        }
        
        const sectionIndex = parseInt(part.substr(0, 2)) - 1;
        const section = this.project.meta.sections[sectionIndex];
        this.section = {
          index: sectionIndex,
          name: section.name,
        };
        this.data = cloneDeep(section.quiz);
  
        const nSections = this.project.meta.sections.length;

        // get prev chapter
        let prevChapterSectionIndex = sectionIndex;
        let prevChapterChapterIndex = section.items.length - 1;
        
        const prevSectionIndexStr = (prevChapterSectionIndex + 1).toString().padStart(2, '0');
        const prevChapterIndexStr = (prevChapterChapterIndex + 1).toString().padStart(2, '0');
        this.prevChapter = {
          route: `/projects/${slug}/${prevSectionIndexStr}-${prevChapterIndexStr}`,
          accessible: this.isTutorialAccessible(slug, prevChapterSectionIndex, prevChapterChapterIndex),
        };
        
        // get next chapter
        let nextChapterSectionIndex = sectionIndex + 1;
        let nextChapterChapterIndex = 0;
        
        if (nextChapterSectionIndex < nSections) {
          const nextSectionIndexStr = (nextChapterSectionIndex + 1).toString().padStart(2, '0');
          const nextChapterIndexStr = (nextChapterChapterIndex + 1).toString().padStart(2, '0');
          this.nextChapter = {
            route: `/projects/${slug}/${nextSectionIndexStr}-${nextChapterIndexStr}`,
            accessible: this.isTutorialAccessible(slug, nextChapterSectionIndex, nextChapterChapterIndex),
          };
        }

        this.loading = false;
      } catch (error) {
        this.$router.push({ name: '404' });
      }
    },
    async submitAnswers() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      await delay(400);

      const validRefs = [];
      const validItems = [];
      for (const [index, question] of Object.entries(this.data.questions)) {
        validRefs[index] = this.$refs.questions[index].checkIsValid(question.answer);
        if (validRefs[index] && !this.finishedItems.includes(`q${index}`)) {
          validItems.push(`s${this.section.index + 1}-q${parseInt(index) + 1}`); // 1-indexed for server-side parsing
        }
      }

      this.submitted = true;
    },
    async toggleBottomBar(on) {
      this.showTOC = on;
      if (on) {
        this.prevScroll = window.scrollY;
        await delay(500);
        blockPageScroll(true);
      }
      else {
        blockPageScroll(false);
        window.scrollTo({ top: this.prevScroll, behavior: 'instant' });
      }
    },
  },
}
</script>

<style lang="sass" scoped>
#tutorial .inner-container:deep(img)
  max-width: 100% !important

.check-button
  width: 40px
  height: 40px
  min-width: auto
  min-height: auto
  border: 1px solid $grey-7

.check-label
  position: relative
  height: 24px
.check-label > div
  position: absolute
  left: 0
  top: 0
</style>
