<template>
  <div class="text-center">
    <q-separator></q-separator>
    <div class="q-py-lg text-grey-7">
      <Navigation class="q-mb-lg justify-center"></Navigation>
      <div id="donation" class="bg-grey-2 q-pa-md rounded-borders">
        Wanna support my work? Consider <a href="https://www.patreon.com/minapecheux" target="_blank">joining my Patreon <q-icon name="mood"></q-icon></a>
      </div>
      <div class="q-my-lg row justify-center q-col-gutter-md">
        <a href="https://www.youtube.com/@minapecheux" target="_blank"><IconYoutube :color="iconsColor" :size="iconsSize"></IconYoutube></a>
        <a href="https://www.instagram.com/minapecheuxcg/" target="_blank"><IconInstagram :color="iconsColor" :size="iconsSize"></IconInstagram></a>
        <a href="https://bsky.app/profile/minapecheux.bsky.social" target="_blank"><IconBluesky :color="iconsColor" :size="iconsSize"></IconBluesky></a>
        <a href="https://twitter.com/MinaPecheux" target="_blank"><IconTwitter :color="iconsColor" :size="iconsSize"></IconTwitter></a>
        <a href="https://github.com/MinaPecheux" target="_blank"><IconGithub :color="iconsColor" :size="iconsSize"></IconGithub></a>
        <a href="https://medium.com/@mina-pecheux" target="_blank"><IconMedium :color="iconsColor" :size="iconsSize"></IconMedium></a>
        <a href="https://www.linkedin.com/in/mina-pecheux/" target="_blank"><IconLinkedin :color="iconsColor" :size="iconsSize"></IconLinkedin></a>
      </div>
      <div>
        Mina Pêcheux © {{ year }} | Powered by <a href="https://vuejs.org/" target="_blank">Vue.JS</a> and <a href="https://quasar.dev/" target="_blank">Quasar</a><br />
        Icons by <a href="https://fonts.google.com/icons?icon.set=Material+Icons" target="_blank">Material Icons</a>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from './Navigation.vue';

import IconBluesky from '@/components/icons/IconBluesky.vue';
import IconGithub from '@/components/icons/IconGithub.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';
import IconLinkedin from '@/components/icons/IconLinkedin.vue';
import IconMedium from '@/components/icons/IconMedium.vue';
import IconTwitter from '@/components/icons/IconTwitter.vue';
import IconYoutube from '@/components/icons/IconYoutube.vue';

export default {
  name: 'Footer',
  components: {
    Navigation,

    IconBluesky,
    IconGithub,
    IconInstagram,
    IconLinkedin,
    IconMedium,
    IconTwitter,
    IconYoutube,
  },
  computed: {
    iconsColor() { return '#222222'; },
    iconsSize() { return 36; },
    year() {
      return (new Date()).getFullYear();
    }
  },
}
</script>

<style scoped>
#donation {
  width: fit-content;
  margin: auto;
  font-size: 1.1rem;
}
</style>
